// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCeL4krZksMdI4SimvZS2t387GM_cqJFhc",
  authDomain: "woaommainsite.firebaseapp.com",
  projectId: "woaommainsite",
  storageBucket: "woaommainsite.appspot.com",
  messagingSenderId: "922586275994",
  appId: "1:922586275994:web:9abbd330b2c543d588ff23",
  measurementId: "G-NBGTF33PX2"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

const analytics = getAnalytics();

export { auth, db };