import React from 'react'
import PowerAppsGif from '../../assets/PowerAppGif.gif'
import PowerAppsGif2 from '../../assets/PowerAppsGif2.gif'
function HeroLanding() {

    // Replace javascript:void(0) path with your path



    return (
        < >
            <section className="py-24 mx-auto pb-4 px-4 sm:px-8 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] ">
                <div className="text-center space-y-4 flex items-center justify-center">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl max-w-screen-xl ">
                    We Solve Business Challenges Using
                         <span className="text-blue-900"> Microsoft Technologies</span>
                    </h1>
                </div>
                <div className="text-center space-y-4 flex items-center justify-center py-4">
                <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
                    PowerApps Design and Development Agency  |  100% US-Based
                    </p>
                </div>
                <div className="mt-4 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <a href="javascript:void(0)" className="px-10 py-3.5 w-full text-gray-500 text-center border border-blue-500 rounded-md duration-300 hover:text-blue-900 hover:shadow block sm:w-auto">
                    TALK TO A DEVELOPER
                    </a>
                </div>
                <div className="mt-14 flex items-center justify-center">
                    <img src={PowerAppsGif2} className="w-[1000px] shadow-lg rounded-lg border" alt="" />
                </div>
            </section>
        </>
    )
}

export default HeroLanding
