import React, { useState } from 'react';
import { X, Download } from 'lucide-react';
import { db } from "../firebase";
import InstagramStarterKit from "../components/InstagramStarterKit.js"


function Modal({onClose}) {
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        db.collection('email')
        .add({
          email: email,


        })
        .then(() => {
            alert('Form has be submitted👍, we will reach out to you soon!')
          })
          .catch((error) => {
            alert(error.message);
          });

          setEmail("");

        };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm'>
    <div>
    <button onClick={onClose}><X/></button>
    </div>
    <div className='bg-[#A0B3FA] rounded-lg px-20 py-10 flex flex-col gap-5 items-center mx-4 text-black'>
        <h1 className='text-3xl font-extrabold font-mono text-center'>Get Your Free Social Media Design Starter Kit!</h1>
        <p className='text-1xl font-medium max-w-md text-center'>Upgrade your social media presence with 10 editable Canva templates Instagram. Save time & create stunning visuals to boost your brand! Get yours now!</p>
        <form onSubmit={handleSubmit}>
            <input
                type='email'
                placeholder='Enter you email'
                onChange={(e) => setEmail(e.target.value)}
         required value={email}
                className='w-full px-4 py-3 text-black border-gray-300 rounded-md'
            />
            <button className='mt-4 ww-full flex items-center justify-center gap-2 px-5 py-3 font-medium rounded-md bg-[#FAE7A0] text-black'><Download/> Download Designs <InstagramStarterKit/></button>
        </form>
    </div>

    </div>
  )
}

export default Modal
