import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Capabilities from './pages/Capabilities';
import About from './pages/About';
import Contact from './pages/Contact';
import Work from './pages/Work';
import Careers from './pages/Careers';
import 'font-awesome/css/font-awesome.min.css';
import ReactGA from 'react-ga';
import Subscription from './pages/Subscription';
import SocialMediaContent from './pages/LandingPages/SocialMediaContent';
import CaseStudyLanding from './pages/CaseStudyLanding';
import PowerApps from './pages/PowerApps';
ReactGA.initialize('G-Z3LNGE5FZ1');






function App() {
  return (
    <Router>


          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/Capabilities" element={<Capabilities />} />
          </Routes>
          <Routes>
            <Route path="/Careers" element={<Careers/>} />
          </Routes>
          <Routes>
            <Route path="/About" element={<About/>} />
          </Routes>
          <Routes>
            <Route path="/Contact" element={<Contact/>} />
          </Routes>
          <Routes>
            <Route path="/Work" element={<Work/>} />
          </Routes>
          <Routes>
            <Route path="/Subscription" element={<Subscription/>} />
          </Routes>
          <Routes>
            <Route path="/SMLandPage" element={<SocialMediaContent/>} />
          </Routes>
          <Routes>
            <Route path="/CaseStudyLanding" element={<CaseStudyLanding/>} />
          </Routes>
          <Routes>
            <Route path="/PowerApps" element={<PowerApps/>} />
          </Routes>

    </Router>




  )
}

export default App;
