import { FaArrowUp } from "react-icons/fa";
import Typed from "react-typed";
import "../App.css";
import React from "react";
import video15 from "../assets/HeroVideo2.mp4";
import { useState, useEffect } from "react";
import Logo from "../assets/RaveLogo.png";
import { db } from "../firebase";
import "../App.css";
import CircularText from "../assets/Award.png";
import CircularText2 from "../assets/Award3.png";
import MiddleImage from "../assets/MiddleImage.png";

import { Helmet } from "react-helmet";

function Hero() {
  const [state, setState] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection("email")
      .add({
        email: email,
      })

      .then(() => {
        alert("Email has be submitted 👍");
      })
      .catch((error) => {
        alert(error.message);
      });

    setEmail("");
  };

  const navigation = [
    { title: "Home", path: "/" },
    { title: "Subscription", path: "/Subscription" },
    { title: "Benefits", path: "#benefits" },
    { title: "Work", path: "#Project" },
    { title: "PowerApps", path: "/PowerApps" },
    // { title: "Careers", path: "/Careers" },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".menu-btn")) setState(false);
    };
  }, []);




  return (
<section className="bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] h-full">
  <div className="px-4 mx-auto md:px-8 min-h-[600px] max-h-[800px]">
        {/* <video
          src={video15}
          autoPlay
          loop
          muted
          className="scale-95 rounded-3xl absolute inset-0 z-0 w-full h-5/6 object-cover delay-300  bg-bottom flex items-center justify-center"
          alt="Hero Video"
        ></video> */}
        <nav>
          <div className="items-center px-4 mx-auto md:flex md:px-8 pt-4 flex justify-center">
            <div className="flex items-center justify-between mr-14 py-10">
              <a href="/">
                <img
                  src={Logo}
                  width={150}
                  height={100}
                  alt="RaveUX logo"
                  className="max-w-md"
                />
              </a>
              <div className="md:hidden">
                <button
                  className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                  onClick={() => setState(!state)}
                >
                  {state ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 8h16M4 16h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-10 md:block md:pb-0 md:mt-0 max-w-2xl mr-10
          bg-[#FAE7A0] backdrop-blur-sm bg-[#A0B3FA]/30 rounded-3xl ${
            state ? "block" : "hidden"
          }`}
            >
              <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                {navigation.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className=" text-black hover:text-[#A0B3FA] hover-underline py-2 px-3 over:-translate-y-1 hover:scale-100 duration-300 font-mono hover:-translate-y-1"
                    >
                      <a href={item.path}>{item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="hidden md:inline-block">
              <a
                href="/Contact"
                className=" md:inline-flex  focus:shadow-none focus:ring-2 ring-offset-2 ring-indigo-600 sm:mt-0 sm:ml-3 sm:w-auto transition ease-in-out delay-150
               text-black hover:text-[#A0B3FA] hover:bg-blue text-blue-dark font-semibold py-2 px-4
                text-sm rounded-3xl hover:-translate-y-1 hover:scale-100 duration-300 font-mono backdrop-blur-sm bg-[#A0B3FA]/30"
              >
                Start a Project
              </a>
            </div>
          </div>
        </nav>

        <div className="mx-auto flex flex-col relative mt-20 items-center justify-center">
          <div className="max-w-4xl mb-6">
            <h2 className="mb-6 font-thin tracking-tight text-black md:text-5xl text-4xl sm:leading-none font-mono text-center">
              #1 Veteran Owned Creative Agency <br/>
              <span className="inline-block text-deep-purple-accent-40 font-mono">
                <Typed
                  strings={[" Social Media", " Graphic Design", " UX Design"]}
                  typeSpeed={170}
                  backSpeed={180}
                  loop
                  className="text-[#A0B3FA] "
                />

              </span>
            </h2>

            <p className="text-base text-black md:text-lg font-mono text-center">
              Veteran-Owned Business? Get Fresh Designs Delivered Monthly with
              Affordable Plans.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center bg-white  p-1 sm:max-w-md sm:mx-auto border-[1px] rounded-full border-gray-800 py-2 px-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-transparent duration-300"
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email address...."
                className="text-gray-500 w-48 p-2 outline-none"
              />
              <button className="p-2 px-3 rounded-full font-medium font-mono text-black bg-[#FAE7A0] hover:bg-[#A0B3FA] active:bg-[#A0B3FA] duration-150 outline-none shadow-md focus:shadow-none sm:px-4">
                Start for free
              </button>
            </form>
            {/* <div className="border-[1px] rounded-xl border-gray-800 py-2 px-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-transparent duration-300">
                <a
                  href="#plans"
                  className="flex items-center justify-center font-mono text-black"
                >
                  See plans
                  <FaArrowUp className="rotate-45 text-xl ml-2 text-black" />
                </a>
              </div> */}
          </div>
        </div>
        <div className="flex justify-center items-center gap-x-4 text-[#FAE7A0] text-sm py-4 font-mono">
          <div className="flex">
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" />
            </svg>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" />
            </svg>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" />
            </svg>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" />
            </svg>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" />
            </svg>
          </div>
          <p className="text-gray-700">
            <span className="text-gray-700">5.0</span> by over 200+ happy
            customers
          </p>
        </div>
      <div className="absolute md:right-10 right-10 bottom-[500px] md:block hidden">
        <img src={CircularText} className=" md:w-40  right-0" alt="Availble" />
        <img src={CircularText2} className=" md:w-40  right-0" alt="Availble" />
      </div>
      </div>

      <Helmet>
   <script>
    {`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '950404');
     `}
   </script>
</Helmet>



    </section>
  );
}





export default Hero;
