import React from 'react'

function SupportLanding() {

  return (
    <section className="py-24 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
        <div className="sm:hidden lg:block lg:max-w-xl">
            <img src="https://images.unsplash.com/photo-1622675363311-3e1904dc1885?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" className="rounded-lg" alt="" />
        </div>
        <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
            <div className="max-w-2xl">
                <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Microsoft Power Apps Managed Services: A Different Kind of Support
                </h3>
                <p className="mt-3 max-w-xl">
                Do you have an existing app or entire PowerApps portal that requires ongoing updates? Our Professional Support Services, one of the only Gartner-recommended alternatives to Microsoft support will give you direct access to a team of experts who will keep your PowerApps solution performing optimally.
                </p>
            </div>

        </div>
    </div>
</section>
  )
}

export default SupportLanding
