import { useEffect } from 'react';
import DesignSamples0 from '../assets/DesignSamples.jpg';
import DesignSamples1 from '../assets/DesignSamples1.png';
import DesignSamples2 from '../assets/DesignSamples2.jpg';
import DesignSamples3 from '../assets/DesignSamples3.jpg';
import DesignSamples4 from '../assets/DesignSamples4.jpg';
import DesignSamples5 from '../assets/DesignSamples5.jpg';
import DesignSamples6 from '../assets/DesignSamples6.jpg';
import DesignSamples7 from '../assets/DesignSamples7.png';
import DesignSamples8 from '../assets/DesignSamples11.png';
import DesignSamples18 from '../assets/DesignSamples8.mp4';
import DesignSamples9 from '../assets/DesignSamples9.jpg';
import DesignSamples10 from '../assets/DesignSamples10.jpg';
import DesignSamples11 from '../assets/DesignSamples11.gif';
import DesignSamples12 from '../assets/DesignSamples12.png';
import DesignSamples17 from '../assets/DesignSamples17.jpg';
import Military1 from '../assets/Military14.jpeg'
import Military2 from '../assets/Military2.png'
import Military3 from '../assets/Military3.png'
import Military4 from '../assets/Military4.png'
import Military5 from '../assets/Military5.jpeg'
import Military6 from '../assets/Military6.gif'
import Military7 from '../assets/Military7.png'
import Military8 from '../assets/Military8.png'
import Military9 from '../assets/Military9.jpeg'
import Military10 from '../assets/Military19.png'
import Military11 from '../assets/Military11.png'
import Military12 from '../assets/Military12.png'
import AOS from "aos";



function DesignSamples() {
  useEffect(() => {
    AOS.init({duration:1200})
  })
      return (
        <section className='py-4 text-black  bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]' id='Project'>
       <div className="max-w-screen-xl mx-auto px-4 md:px-8" data-aos="fade-up">
        <div className="relative mx-auto sm:text-left mt-6 font-mono">
          <h3 className="text-md font-semibold text-gray-700 border-[1px] rounded-3xl border-gray-700 w-96 p-2 flex items-center justify-center mb-4 hover:-translate-y-1 hover:scale-100 duration-300">
            Our Graphic Social Media Design Work
          </h3>
                <p className="mt-3 text-black font-mono">

                </p>

            </div>

        </div>

        <div class="container mx-auto px-5 py-2 lg:px-32 lg:pt-24" data-aos="fade-up">
  <div class="-m-1 flex flex-wrap md:-m-2" data-aos="fade-up">
    <div class="flex w-1/2 flex-wrap">

      <div class="w-1/2 p-1 md:p-2 group-hover:opacity-50 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">
        <img
          alt="gallery"
          class="block h-full w-full rounded-lg object-cover object-center"
          src={DesignSamples12} />
    </a>
      </div>

      <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">
        <img
          alt="gallery"
          class="block h-full w-full rounded-lg object-cover object-center"
          src={DesignSamples4} />
          </a>
      </div>
      <div class="w-full p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">
        <img
          alt="gallery"
          class="block h-full w-full rounded-lg object-cover object-center"
          src={Military1}  />
          </a>
      </div>
    </div>
    <div class="flex w-1/2 flex-wrap ">
      <div class="w-full p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">

<video
          src={DesignSamples18}
          autoPlay
          loop
          muted
          className="block h-full w-full rounded-lg object-cover object-center"
          alt="Hero Video"
        ></video>


          </a>
      </div>
      <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">
        <img
          alt="gallery"
          class="block h-full w-full rounded-lg object-cover object-center"
          src={DesignSamples0}  />
          </a>
      </div>


      <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
      <a href="/SMLandPage" target="_blank" rel="noreferrer">
        <img
          alt="gallery"
          class="block h-full w-full rounded-lg object-cover object-center"
          src={DesignSamples17}  />
          </a>
      </div>
    </div>
  </div>
</div>
</section>
      );
}

export default DesignSamples
