import React from 'react'
import Step1 from '../../assets/step1.svg'
import Step2 from '../../assets/step2.svg'
import Step3 from '../../assets/step3.svg'
import Step4 from '../../assets/step4.svg'

function BenefitsLanding() {
    const features = [
        {
            icon:
           <img src={Step1} alt="/" />,
            step: "STEP 1",
            title: "Schedule a Free Consultation",
            desc: "You’ve got a business challenge. Our team is ready to talk to you about finding the right solution on a free 30-minute discovery call."
        },
        {
            icon:
            <img src={Step2} alt="/" />,
            step: "STEP 2",
            title: "Choose The Service That’s Right for You",
            desc: "Your budget, your needs, your capabilities. We will present you with options and you choose the one that works best for you."
        },
        {
            icon:
            <img src={Step3} alt="/" />,
            step: "STEP 3",
            title: "Meet With Your New Senior-Level Expert",
            desc: "Get senior-level staff assigned directly to your project. They’ll learn everything about your business and get to work."
        },
        {
            icon:
            <img src={Step4} alt="/" />,
            step: "STEP 4",
            title: "See Results Immediately",
            desc: "Don’t wait to get faster, more efficient, and more capable. You will immediately see results and the difference in working with us."
        },

    ]

    return (
        <section className="py-14 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="relative max-w-2xl mx-auto sm:text-center">
                    <div className="relative z-10">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        How Power Apps Consulting Will Work For You
                        </h3>

                    </div>
                    <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                </div>
                <div className="relative mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="bg-white space-y-3 p-4 border rounded-lg">
                                    <div className="text-indigo-600 pb-3 w-14">
                                        {item.icon}
                                    </div>
                                    <h2 className='text-sm'>
                                        {item.step}
                                    </h2>
                                    <h4 className="text-lg text-gray-800 font-semibold">
                                        {item.title}
                                    </h4>
                                    <p>
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default BenefitsLanding
