import React from 'react'
import Sceen1 from '../../assets/screen-1-2.png'

function CaseStudyLanding() {



  return (
    <section className="py-14 mx-auto bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" id='casestudy'>
    <div className='flex items-center justify-center'>
    <div className="relative overflow-hidden w-[1450px] mx-4 px-4 py-14 rounded-2xl bg-blue-900 md:px-8 md:mx-8">
        <div className="relative z-10 max-w-xl mx-auto sm:text-center">
            <div className="space-y-3">

                <h3 className="text-3xl text-white font-bold">
                Get The Job Done Right The First Time
                </h3>
                <p className="text-blue-100 leading-relaxed">
                You have a business to run. That’s why our business is to make sure your Power Apps solution does what you need it to do for you, every time.
                </p>
                <p className="text-blue-100 leading-relaxed">
                We’ve been building Power Apps solutions since it was released. We can do it for you.
                </p>
                <div className="mt-4 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <a href="/CaseStudyLanding" className="px-10 py-3.5 w-full text-white text-center border border-white rounded-md duration-300 hover:text-blue-200 hover:border-blue-200 hover:shadow block sm:w-auto">
                    TALK TO AN EXPERT
                    </a>
                </div>
            </div>
            <div className="mt-6">


            </div>
        </div>
        <div className="absolute inset-0 w-full h-full" style={{ background: "linear-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)" }}></div>
    </div>
    </div>

    <section className="relative max-w-screen-xl mx-auto py-20 px-4 md:px-8">
            <div className="absolute top-0 left-0 w-full h-full bg-white opacity-40"></div>
            <div className="relative z-10 gap-5 items-center lg:flex">
                <div className="flex-1 max-w-lg py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left">
                <h3 className="text-blue-800 font-semibold my-4">
                FEATURED CASE STUDY
                        </h3>
                    <h3 className="text-3xl text-gray-800 font-semibold md:text-4xl">
                    Project Intake App Improves Project<span className="text-blue-800"> Request Accuracy</span>
                    </h3>
                    <p className="text-gray-500 leading-relaxed mt-8">
                    One of the nation’s fastest-growing quick-service restaurant chains was in need of a project request solution that would not only improve the intake process but improve communication across their team of business users. Learn how the Power Platform – including Power Apps and Power Automate provided the solution.
                    </p>
                    <a
                        className="mt-5 px-4 py-2 text-blue-800 font-medium bg-indigo-50 rounded-full inline-flex items-center"
                        href="/CaseStudyLanding">
                        READ THE CASE STUDY
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1 duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                    </a>
                </div>
                <div className="flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-auto">
                    <img
                        src={Sceen1}
                        alt=""
                        className="w-[250px] ml-28"
                    />

                </div>
            </div>
        </section>
</section>

  )
}

export default CaseStudyLanding
