import React from 'react'
import '../App.css'
import First1 from '../assets/Pickel.jpg'
import First2 from '../assets/First2.jpg'
import First3 from '../assets/First3.jpg'
import First4 from '../assets/First4.jpg'
import Military1 from '../assets/Military1.jpeg'
import Military2 from '../assets/Military2.png'
import Military3 from '../assets/Military3.png'
import Military4 from '../assets/Military4.png'
import Military5 from '../assets/Military5.jpeg'
import Military6 from '../assets/Military6.gif'
import Military7 from '../assets/Military7.png'
import Military8 from '../assets/Military8.png'
import Military9 from '../assets/Linkiy.jpg'
import Military10 from '../assets/Military19.png'
import Military11 from '../assets/Military11.png'
import Military12 from '../assets/Military12.png'



function Horizontal() {
  return (
    <div class="relative bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] ">
<ul class="container-snap py-20 pb-8 px-[1vw] w-full md:flex gap-8 snap-x overflow-x-auto no-scrollbar self-center bg-transparent">
    <li class="snap-center font-mono">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden rounded-3xl md:mt-0 mt-4 ">
            <img src={First1} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>
                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Social Media Content</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-sm font-medium bg-white rounded-lg text-slate-900">Get Started</a>
            </div>
        </div>
    </li>

    <li class="snap-center font-mono">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden rounded-3xl md:mt-0 mt-4 ">
            <img src={Military2} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>
                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Web/UX Design</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-sm font-medium bg-white rounded-lg text-slate-900">Get Started</a>
            </div>
        </div>
    </li>


    <li class="snap-center font-mono">
        <div class="relative flex-shrink-0 max-w-[95vw] overflow-hidden rounded-3xl md:mt-0 mt-4 ">
            <img src={First3} alt="" class="absolute inset-0 object-cover object-bottom w-full h-full " />

            <div class="absolute inset-0 w-full h-full bg-gradient-to-br from-black/75"></div>

            <div class=" relative h-96 w-[500px] p-12 flex flex-col justify-between items-start">
                <div>

                    <h2 class=" mt-3 text-3xl font-semibold tracking-tight text-white">Graphic Design</h2>
                </div>

                <a href="/Subscription" class="px-4 py-3 text-sm font-medium bg-white rounded-lg text-slate-900">Get Started</a>
            </div>
        </div>
    </li>

</ul>
</div>
  )
}

export default Horizontal
