import React from 'react'
import NavLanding from '../components/NewHomeLandpage/NavLanding'
import FooterLanding from '../components/NewHomeLandpage/FooterLanding'

function CaseStudyLanding() {
  return (
    <div>
    <NavLanding/>
    <FooterLanding/>

    </div>
  )
}

export default CaseStudyLanding
