import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";


function Careers() {
  return (
    <section className=" bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
    <Nav/>

    <div class="flex items-center justify-center py-20">
<h1 class="animate-slide text-border mb-52 bg-[url('https://images.pexels.com/photos/957085/milky-way-starry-sky-night-sky-star-957085.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')] bg-clip-text py-4 text-6xl font-extrabold text-transparent">Career Page Coming Soon!</h1>
</div>
<Footer/>
  </section>
  );
}

export default Careers
