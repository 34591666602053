import { useState, useEffect } from "react";
import Logo from "../assets/RaveLogo.png";
// import AOS from "aos";
// import "aos/dist/aos.css";
// data-aos="fade-up"

function Nav() {

  // useEffect(() => {
  //   AOS.init({ duration: 1200 });
  // });
  const [state, setState] = useState(false);

  const navigation = [
    { title: "Home", path: "/" },
    { title: "Subscription", path: "/Subscription" },
    { title: "Benefits", path: "#benefits" },
    { title: "Work", path: "#project" },
    { title: "PowerApps", path: "/PowerApps" },
  ];

  return (
    <nav
      className=" w-full border-b md:border-0 md:static font-mono bg-transparent">
      {/* <div class="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"></div> */}

      <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8 pt-4 ">
        <div className="flex items-center justify-between py-3 md:py-5 md:block">
        <a href="/">
                <img
                  src={Logo}
                  width={150}
                  height={100}
                  alt="RaveUX logo"
                  className="max-w-md"
                />
              </a>
              <div className="md:hidden">
                <button
                  className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                  onClick={() => setState(!state)}
                >
                  {state ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 8h16M4 16h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-10 md:block md:pb-0 md:mt-0 max-w-2xl mr-10
          bg-[#FAE7A0] backdrop-blur-sm bg-white/30 rounded-3xl ${state ? "block" : "hidden"}`}
            >
              <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                {navigation.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className=" text-black hover:text-[#A0B3FA] hover-underline py-2 px-3 over:-translate-y-1 hover:scale-100 duration-300 font-mono hover:-translate-y-1 hover:scale-100 duration-300"
                    >
                      {/*
text-white hover:text-[#FAE7A0] bg-transparent hover:bg-blue text-blue-dark font-semibold py-2 px-4 hover:underline rounded-2xl uppercase text-sm hover:-translate-y-1 hover:scale-100 duration-300 mt-2 */}
                      <a href={item.path}>{item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="hidden md:inline-block">
              <a
                href="/Contact"
                className=" md:inline-flex  focus:shadow-none focus:ring-2 ring-offset-2 ring-indigo-600 sm:mt-0 sm:ml-3 sm:w-auto transition ease-in-out delay-150
               text-black hover:text-[#A0B3FA] hover:bg-blue text-blue-dark font-semibold py-2 px-4
                text-sm rounded-3xl hover:-translate-y-1 hover:scale-100 duration-300 font-mono backdrop-blur-sm bg-white/30"
              >
                Book a Call
              </a>
            </div>
          </div>
    </nav>
  );
}

export default Nav;
