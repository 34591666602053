import { useState } from "react";
import "../App.css";
const types = ["Social Media", "Graphics", "UX Design"];
const Radio = ({ id, value, className, onChange, checked, ...args }) => {
  return (
    <div className={className}>
      <input
        type="radio"
        value={value}
        id={id}
        name="tabs"
        onChange={onChange}
        {...args}
      />
      <label className={checked && `active`} htmlFor={id}>
        {value}
      </label>
    </div>
  );
};

export default function Tabs() {
  const [value, setValue] = useState("Social Media");
  const planthree = {
    name: "UX Design Subscription",
    desc:  "UX Designer embedded with your team to create custom ux design for your company.",
    price: 3500,
    isMostPop: true,
    link: "https://buy.stripe.com/3cs4jg5ps6oz1KUbII",
    features: [
        "Dedicated UX team & design lead",
        "Designs in 48 hrs (Mon-Fri)",
        "Use your tools, get fast designs",
        "Pause or cancel anytime",
    ],
}

  const plantwo = {
    name: "Graphics Subscription",
    desc:  "Subscribers get 10 graphic content monthly for email, web and social media design content.",
    price: 600,
    isMostPop: true,
    link: "https://buy.stripe.com/00g3fcdVY9AL9dm289",
    features: [
        "Request graphics one at a time",
        "Designs in 48 hrs (Mon-Fri)",
        "Use your tools, get expert designs",
        "Pause or cancel anytime",

    ],
}
  const plan = {
    name: "Social Media Subscription",
    desc: "Subscribers get 15 unique and engaging content, each month.",
    price: 400,
    isMostPop: true,
    link: "https://buy.stripe.com/3cs4jg5ps6oz1KUbII",
    features: [

  "Manage all your brands' social media",
  "Designer in your agile meetings",
  "Level up your content with stock photos",
  "Pause or cancel anytime",
  "Fresh designs in 48 hrs (Mon-Fri)",
    ],
}


  let isOne = value === "Social Media";
  let isTwo = value === "Graphics";
  let isThree = value === "UX";
  return (
    <div className="App">
      <div className="tabs">
        {types.map((item) => (
          <Radio
            key={item}
            id={item}
            value={item}
            checked={value === item}
            className="hello"
            onChange={(e) => setValue(e.target.value)}
          />
        ))}
      </div>

      {value === types[0] && <p>
        <div className="flex-1 flex flex-col border-y mt-6 md:max-w-xl md:rounded-xl md:border md:border-x-none md:shadow-lg md:mt-0">
                <div className="p-4 py-8 border-b md:p-8 bg-white">
                    <div className="justify-between flex">
                        <div className="max-w-xs">
                            <span className='text-2xl text-gray-700 font-semibold sm:text-3xl'>
                                {plan.name}
                            </span>
                            <p className="mt-3 sm:text-sm">
                                {plan.desc}
                            </p>
                        </div>
                        <div className='flex-none text-gray-700 text-2xl font-semibold sm:text-3xl'>
                            ${plan.price} <span className="text-xl text-gray-600 font-normal">/mo</span>
                        </div>
                    </div>
                    <a href='https://buy.stripe.com/3cs4jg5ps6oz1KUbII'>
                    <button className='mt-4 px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 bg-[#FAE7A0] hover:bg-transparent hover:border-2 hover:border-black'>
                    Subscribe Now
                    </button>
                    </a>
                </div>
                <ul className='p-4 space-y-3 sm:grid sm:grid-cols-2 md:block md:p-8 lg:grid bg-white'>
                    <div className="pb-2 col-span-2 text-gray-800 font-medium">
                        <p>Features</p>
                    </div>
                    {
                        plan.features.map((featureItem, idx) => (
                            <li key={idx} className='flex items-center gap-3'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-5 w-5 text-[#A0B3FA]'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'>
                                    <path
                                        fill-rule='evenodd'
                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                        clip-rule='evenodd'></path>
                                </svg>
                                {featureItem}
                            </li>
                        ))
                    }
                </ul>
            </div>
      </p>}
      {value === types[1] && <p> <div className="flex-1 flex flex-col border-y mt-6 md:max-w-xl md:rounded-xl md:border md:border-x-none md:shadow-lg md:mt-0">
                <div className="p-4 py-8 border-b md:p-8 bg-white">
                    <div className="justify-between flex">
                        <div className="max-w-xs">
                            <span className='text-2xl text-gray-700 font-semibold sm:text-3xl'>
                                {plantwo.name}
                            </span>
                            <p className="mt-3 sm:text-sm">
                                {plantwo.desc}
                            </p>
                        </div>
                        <div className='flex-none text-gray-700 text-2xl font-semibold sm:text-3xl'>
                            ${plantwo.price} <span className="text-xl text-gray-600 font-normal">/mo</span>
                        </div>
                    </div>
                    <a href="https://buy.stripe.com/00g3fcdVY9AL9dm289">
                    <button className='mt-4 px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 bg-[#FAE7A0] hover:bg-transparent hover:border-2 hover:border-black'>
                        Subscribe Now

                    </button>
                    </a>
                </div>
                <ul className='p-4 space-y-3 sm:grid sm:grid-cols-2 md:block md:p-8 lg:grid bg-white'>
                    <div className="pb-2 col-span-2 text-gray-800 font-medium">
                        <p>Features</p>
                    </div>
                    {
                        plantwo.features.map((featureItem, idx) => (
                            <li key={idx} className='flex items-center gap-3'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-5 w-5 text-[#A0B3FA]'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'>
                                    <path
                                        fill-rule='evenodd'
                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                        clip-rule='evenodd'></path>
                                </svg>
                                {featureItem}
                            </li>
                        ))
                    }
                </ul>
            </div></p>}


            {value === types[2] && <p> <div className="flex-1 flex flex-col border-y mt-6 md:max-w-xl md:rounded-xl md:border md:border-x-none md:shadow-lg md:mt-0">
                <div className="p-4 py-8 border-b md:p-8 bg-white">
                    <div className="justify-between flex">
                        <div className="max-w-xs">
                            <span className='text-2xl text-gray-700 font-semibold sm:text-3xl'>
                                {planthree.name}
                            </span>
                            <p className="mt-3 sm:text-sm">
                                {planthree.desc}
                            </p>
                        </div>
                        <div className='flex-none text-gray-700 text-2xl font-semibold sm:text-3xl'>
                            ${planthree.price} <span className="text-xl text-gray-600 font-normal">/mo</span>
                        </div>
                    </div>
                    <a href="https://buy.stripe.com/dR60304lodR1exG28a">
                    <button className='mt-4 px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 bg-[#FAE7A0] hover:bg-transparent hover:border-2 hover:border-black'>
                    Subscribe Now

                    </button>
                    </a>
                </div>
                <ul className='p-4 space-y-3 sm:grid sm:grid-cols-2 md:block md:p-8 lg:grid bg-white'>
                    <div className="pb-2 col-span-2 text-gray-800 font-medium">
                        <p>Features</p>
                    </div>
                    {
                        planthree.features.map((featureItem, idx) => (
                            <li key={idx} className='flex items-center gap-3'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-5 w-5 text-[#A0B3FA]'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'>
                                    <path
                                        fill-rule='evenodd'
                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                        clip-rule='evenodd'></path>
                                </svg>
                                {featureItem}
                            </li>
                        ))
                    }
                </ul>
            </div></p>}
    </div>
  );
}
