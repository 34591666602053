import React from "react";
import { useEffect } from "react";
import icon1 from "../assets/icons8-folder.gif";
import icon2 from "../assets/icons8-synchronize (1).gif";
import icon3 from "../assets/icons8-synchronize.gif";
import icon4 from "../assets/icons8-thumbs-up (1).gif";
import icon5 from "../assets/icons8-thumbs-up.gif";
import { FaArrowUp } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";
import Tabs from "./Tabs";

function Pros() {
    useEffect(() => {
        AOS.init({ duration: 1200 });
      });
  return (
    <section className="inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" id="benefits">
      {/* Features Container */}{" "}
      <div className="mx-auto max-w-5xl px-5 py-16 md:px-10 md:py-14 lg:py-28" data-aos="fade-up">
        {" "}
        {/* Features Title */}
        <div className="text-center">
        <h3 className="text-md font-semibold text-gray-700 border-[1px] rounded-3xl border-gray-700 w-40 p-2 flex items-center justify-center mb-6 hover:-translate-y-1 hover:scale-100 duration-300">
            How it Works
          </h3>
          <h2 className="text-3xl font-bold md:text-5xl font-mono">
          We didn’t reinvent the wheel. Just design.
          </h2>
          <p className="mx-auto mb-8 mt-4 max-w-lg text-[#647084] md:mb-12 lg:mb-16 font-sans">
          Design as you know it is out the door. Design as you want it just arrived.
          </p>
        </div>{" "}
        {/* Features Grid */}{" "}
        <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-3">
          {" "}
          {/* Features Item */}{" "}
          <div className="grid gap-10 p-8 md:p-8">
            <img src={icon2} alt="" className="inline-block h-18" />
            <p className="text-xl font-semibold font-mono w-72">Subscribe to a plan & request as many designs as you’d like.</p>

          </div>{" "}
          {/* Features Item */}{" "}
          <div className="grid gap-8 p-8 md:p-10">
            <img
              src={icon1}
              alt=""
              className="inline-block h-18"
            />
            <p className="text-xl font-semibold font-mono w-72">Receive your design within two business days on average.</p>
          </div>{" "}
          {/* Features Item */}{" "}
          <div className="grid gap-8 p-8 md:p-10">
            <img
              src={icon4}
              alt=""
              className="inline-block h-18"
            />
            <p className="text-xl font-semibold font-mono w-72 mt-3">We'll revise the designs until you're 100% satisfied.</p>
          </div>
        </div>
        <div className="flex items-center justify-center">
              <div className="border-[1px] rounded-xl border-gray-800 py-2 px-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-transparent duration-300">
                <a
                  href="#plans"
                  className="flex items-center justify-center font-mono text-black"
                >
                  See plans
                  <FaArrowUp className="rotate-45 text-xl ml-2 text-black" />
                </a>
              </div>
            </div>
            <div className="mt-28" data-aos="fade-up">
            <h2 className="text-3xl font-bold md:text-5xl font-mono text-center">
          Subscription-based plans
          </h2>
          <p className="mx-auto mb-8 mt-4 max-w-lg text-[#647084] md:mb-12 lg:mb-16 font-sans text-center">
          Ditch the design grind. Get the design you crave, delivered monthly.
          </p>
            </div>
            <div className="flex items-center justify-center py-6" data-aos="fade-up">

            <Tabs/>
            </div>
      </div>
    </section>
  );
}

export default Pros;
