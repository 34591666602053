import React from 'react'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import SMBanner from '../../components/LandingPages/SMContent/SMBanner'
import SMHero from '../../components/LandingPages/SMContent/SMHero'
import Tabs from '../../components/Tabs'

function SocialMediaContent() {
  return (
    <div className='bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'>
   <SMBanner/>
    <Nav/>
    <SMHero/>
    <div className='flex items-center justify-center'>
    <Tabs/>
    </div>

   <Footer/>

    </div>
  )
}

export default SocialMediaContent
