import React from 'react'

function ReviewLanding() {
  return (
    <section className="py-14 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-3xl mx-auto">
                    <figure>
                        <blockquote>
                            <p className="text-gray-800 text-xl text-center font-semibold sm:text-2xl italic">
                            “RaveUX and Ruth have helped me build my small Power App into a application used company wide. They are quick to respond to my needs and improve upon my ideas. I could not be happier.”
                            </p>
                        </blockquote>
                        <div className="flex justify-center items-center gap-x-4 mt-6 italic">
                            {/* <img src="https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg" className="w-16 h-16 rounded-full" /> */}
                            <div>
                                <span className="block text-gray-800 font-semibold">SEAN CROSBIE</span>
                            </div>
                        </div>
                    </figure>
                </div>
            </div>
        </section>
  )
}

export default ReviewLanding
