import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function ShortConnect() {
    useEffect(() => {
        AOS.init({ duration: 1200 });
      });


  const contactMethods = [
    {
      icon:
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
  </svg>

      ,
      title: "Schedule a Free Consultation",
      desc: "Talk to our design team and get a personalized plan tailored to your needs.",
      link: {
          name: "Book a call",
          href: "https://cal.com/raveux"
      },
  },
    {
        icon:
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
    </svg>
        ,
        title: "Start a Conversation",
        desc: "Drop us a WhatsApp message and let us know your design challenges.",
        link: {
            name: "Send us DMs",
            href: "https://wa.me/message/WZTWS6SC53XLL1"
        },
    },
    {
        icon:
        <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="none" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_68)"><path fill="currentColor" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z" /></g><defs><clipPath id="clip0_17_68"><path fill="currentColor" d="M0 0h48v48H0z" /></clipPath></defs></svg>

        ,
        title: "Follow Us on Social",
        desc: " Stay connected and see how we help other veteran entrepreneurs.",
        link: {
            name: "Join us on socials",
            href: "https://linkedin.com/company/raveux"
        },
    },


]
  return (

    <div className="py-20 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-12 md:px-8 lg:flex" data-aos="fade-up">
        <div className="max-w-md mt-6">
            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl font-mono">
                Let’s connect
            </h3>
            <p className="mt-3">
            There are a few ways we can chat about how RaveUX can help your veteran-owned tech business thrive:
            </p>
        </div>
        <div>
            <ul className="mt-12 gap-y-6 gap-x-12 items-center md:flex lg:gap-x-0 lg:mt-0">
                {
                    contactMethods.map((item, idx) => (
                        <li key={idx} className="space-y-3 border-t py-6 md:max-w-sm md:py-0 md:border-t-0 lg:border-l lg:px-12 lg:max-w-none">
                            <div className="w-12 h-12 rounded-full border flex items-center justify-center text-gray-700">
                                {item.icon}
                            </div>
                            <h4 className="text-gray-800 text-lg font-medium xl:text-xl font-mono">
                                {item.title}
                            </h4>
                            <p>
                                {item.desc}
                            </p>
                            <a href={item.link.href} className="flex items-center gap-1 text-sm text-indigo-600 duration-150 hover:text-indigo-400 font-medium">
                                {item.link.name}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
</div>
  )
}

export default ShortConnect
