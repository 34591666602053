import React from 'react'

function Work() {
  return (
    <div>
     <h2> Portfolio </h2>
    </div>
  )
}

export default Work
