import React from 'react'
import Tabs from '../components/Tabs'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Pros from '../components/Pros'
import PageSubPros from '../components/PageSubPros'

function Subscription() {
  return (
    <div className='bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'>
    <Nav/>


   <PageSubPros/>
    <Footer/>

    </div>
  )
}

export default Subscription
