import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import QA from "../components/QA";
import StatsBanner from "../components/StatsBanner";
import Team from "../components/Team";
import PricePlans from "../components/PricePlans";
import Pros from "../components/Pros";
import Portfolio from "../components/Portfolio.js";
import Projects from "../components/Projects.tsx";
import Services from "../components/Services.js";
import WorkSection from "../components/WorkSection.js";
import Benefits from "../components/Benefits.js";
import Access from "../components/Access.js"
import TeamNew from "../components/TeamNew.jsx";
import QANew from "../components/QANew.js";
import TeamImage from "../components/TeamImage.js";
import Horizontal from "../components/Horizontal.js";
import NavLanding from "../components/NewHomeLandpage/NavLanding.js";
import HeroLanding from "../components/NewHomeLandpage/HeroLanding.js";
import PowerLanding from "../components/NewHomeLandpage/PowerLanding.js";
import BenefitsLanding from "../components/NewHomeLandpage/BenefitsLanding.js";
import ReviewLanding from "../components/NewHomeLandpage/ReviewLanding.js";
import ConsultingLanding from "../components/NewHomeLandpage/ConsultingLanding.js";
import SupportLanding from "../components/NewHomeLandpage/SupportLanding.js";
import CaseStudyLanding from "../components/NewHomeLandpage/CaseStudyLanding.js";
import ServicesLanding from "../components/NewHomeLandpage/ServicesLanding.js";
import InfoLanding from "../components/NewHomeLandpage/InfoLanding.js";
import FooterLanding from '../components/NewHomeLandpage/FooterLanding.js'
import ConectLanding from "../components/NewHomeLandpage/ConectLanding.js";
import ProsLanding from '../components/NewHomeLandpage/ProsLanding.js'
import Reviews from "../components/Reviews.js";
import DesignSamples from "../components/DesignSamples.js";
import ShortConnect from "../components/ShortConnect.js";
import { Helmet } from "react-helmet";
import EmailPopUp from "../components/EmailPopUp.js";


function Home() {

  return (
    <div class="overflow-visible">
    {/* <NavLanding/>
    <HeroLanding/>
    <ConsultingLanding/>
    <BenefitsLanding/>
    <ReviewLanding/>
    <PowerLanding/>
    <SupportLanding/>
    <CaseStudyLanding/>
    <ServicesLanding/>
    <InfoLanding/>
    <ProsLanding/>
    <ConectLanding/>
    <FooterLanding/> */}
    <Helmet>
   <script>
    {`
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '950404');
     `}
   </script>
</Helmet>



    <Hero />
    <div className="flex items-center justify-center bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
    <Horizontal/>
    </div>

      <StatsBanner />
      <EmailPopUp/>
      <TeamImage/>
      <Portfolio />
      <WorkSection/>
      <Pros />
      <DesignSamples/>


      <Access/>
      <QANew/>
      <Reviews/>
      <ShortConnect/>
      <Footer />



    </div>
  );
}

export default Home;
