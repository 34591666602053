import React from 'react'
import Covid from '../../assets/COVID-19 Antibody Testing Scheduler Power App.png'
import Image from '../../assets/Ramdom5.jpg'

function InfoLanding() {

  return (
    <section className="relative mx-auto py-28 px-4 md:px-8 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] flex items-center justify-center ">
    <div className="absolute top-0 left-0 w-full h-full bg-white opacity-40"></div>
    <div className="relative z-10 gap-5 lg:flex flex items-center justify-center max-w-screen-xl">
        <div className="flex-1 py-5 sm:mx-auto sm:text-center lg:max-w-max lg:text-left ">
            <h3 className="text-3xl text-gray-800 font-semibold md:text-4xl">
            Canvas App or Model Driven:<span className="text-blue-900"> Get a Business App That Works For You</span>
            </h3>
            <p className="text-gray-500 leading-relaxed mt-3">
            Don’t stress over which is right for you. We will help you determine if you have a simple business app that can be easy to create with the right knowledge and the power of a canvas app. If it is complex and requires the additional functionality of a Model Driven App, we can help you as well. Our professional developer will determine which is the better option to make your project successful.
            </p>
            <a
                className="mt-5 px-4 py-2 text-blue-900 font-medium bg-indigo-50 rounded-full inline-flex items-center hover:bg-blue-900 hover:text-white duration-100 translate-x-0"
                href="/Contact">
                Try it out
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1 duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
            </a>
        </div>
        <div className="flex-1 mt-5 mx-auto sm:w-9/12 lg:mt-0 lg:w-auto">
            <img
                src={Image}
                alt=""
                className="w-full"
            />
        </div>
    </div>

</section>


  )
}

export default InfoLanding
