import {useEffect} from "react";
import AppImg1 from "../assets/Design1.jpg";
import Ramdom1 from "../assets/Ramdom1.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import Typed from 'react-typed';
import Images1 from '../assets/masternode.gif'
import Video1 from '../assets/LinkedVideoShow.mp4'
import Video2 from '../assets/TytpesBannerVideo.mp4'



function QA() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });


  return (
    <section className="font-mono inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
      <div className="w-full px-5 py-2 md:px-10 md:py-10">
        <div className="grid grid-cols-2 gap-4">
          {/* Text section */}
          <div className="flex flex-col md:ml-40 items-center justify-center" data-aos="fade-up">

          <Typed
          className='text-xl font-thin md:text-4xl text-center md:leading-10 leading-5 text-gray-700 font-mono bg-[#FAE7A0] rounded-3xl p-5'
            strings={['We handle design ops of all sizes, from basic deployments to full-scale campaigns.', ]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />

          </div>

          {/* Image section */}
          <div className="flex-1 md:col-span-2" data-aos="fade-up">
            {/* <img
              src={Images1}
              className="mx-auto rounded-3xl object-cover md:h-[500px] md:w-[800px] h-[200px] w-[100%] md:mt-0 mt-4"
              alt="MainPhoto"
            /> */}
             <video
              src={Video2}
              className="mx-auto rounded-3xl object-cover md:h-[500px] md:w-[800px] h-[200px] w-[100%] md:mt-0 mt-4"
              alt="MainPhoto"
              autoPlay
          loop
          muted
            >
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QA;
