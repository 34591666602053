import { useEffect } from "react";
import LawyerPhoto from "../assets/Image10.png";
import LashesPhoto from "../assets/Image10.png";
import LeafPhoto from "../assets/Image10.png";
import CBDoil from "../assets/Image10.png";
import Roseoil from "../assets/Image10.png";
import NailPhoto from "../assets/Image10.png";
import Hempoil from "../assets/Image10.png";
import CoffeePhoto from "../assets/Image10.png";
import PortfolioVideo1 from "../assets/PortfolioVideo1.mp4";
import PortfolioVideo2 from "../assets/PortfolioVideo2.mp4";
import PortfolioVideo3 from "../assets/PortfolioVideo3.mp4";
import PortfolioVideo4 from "../assets/PortfolioVideo4.mp4";
import PortfolioVideo5 from "../assets/PortfolioVideo5.mp4";
import PortfolioImage1 from "../assets/PortfolioImage1.png";
import PortfolioImage2 from "../assets/PortfolioImage2.png";
import PortfolioImage3 from "../assets/PortfolioImage3.jpeg";
import PortfolioImage4 from "../assets/PortfolioImage4.png";
import Military1 from '../assets/Military1.jpeg'
import Military2 from '../assets/Military2.png'
import Military3 from '../assets/Military3.png'
import Military4 from '../assets/Military4.png'
import Military5 from '../assets/Military5.jpeg'
import Military6 from '../assets/Military6.gif'
import Military7 from '../assets/Military7.png'
import Military8 from '../assets/Military8.png'
import Military9 from '../assets/Military9.jpeg'
import Military10 from '../assets/Military19.png'
import Military11 from '../assets/Military11.png'
import Military16 from '../assets/Military16.png'


import AOS from "aos";
import "aos/dist/aos.css";

function Portfolio() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  });
  return (
    <section className="py-4 text-white inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" id="Project">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8" data-aos="fade-up">
        <div className="relative mx-auto sm:text-left mt-14 font-mono">
          <h3 className="text-md font-semibold text-gray-700 border-[1px] rounded-3xl border-gray-700 w-36 p-2 flex items-center justify-center mb-4 hover:-translate-y-1 hover:scale-100 duration-300">
            Our work
          </h3>
          {/* <h3 className="text-3xl font-base sm:text-4xl text-gray-700">
            We help brands grow
          </h3> */}
        </div>
      </div>

      <div
        class="container mx-auto px-5 py-2 lg:px-32 lg:pt-14 mb-10"
        data-aos="fade-up"
      >
        <div class="-m-1 flex flex-wrap md:-m-2" data-aos="fade-up">
          <div class="flex w-1/2 flex-wrap">
            <div class="w-1/2 p-1 md:p-2 group-hover:opacity-50 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">

                <video
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={PortfolioVideo1}
                  autoPlay
                  loop
                  muted
                >
                </video>
            </div>

            <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
                <img
                  alt="gallery"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={Military3}
                />
            </div>
            <div class="w-full p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
            <img
                  alt="gallery"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={PortfolioImage3}
                />
            </div>
          </div>
          <div class="flex w-1/2 flex-wrap ">
            <div class="w-full p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
            <video
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={PortfolioVideo3}
                  autoPlay
                  loop
                  muted
                >
                </video>
            </div>
            <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
            <img
                  alt="gallery"
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={Military16}
                />
            </div>

            <div class="w-1/2 p-1 md:p-2 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
            <video
                  class="block h-full w-full rounded-lg object-cover object-center"
                  src={PortfolioVideo2}
                  autoPlay
                  loop
                  muted
                >
                </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
