import {React, useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Tabs from './Tabs';

function PricePlans() {
    useEffect(() => {
        AOS.init({duration:1200})
      })

    const plan = {
        name: "Standard Subscription",
        desc: "One predictable monthly fee. Pause anytime and anywhere.",
        price: 2500,
        isMostPop: true,
        features: [
    "1 or more designers",
      "Dedicated design lead & account manager",
      "Delivery every 48 hrs or less, Mon–Fri",
      "Embedded with your team",
      "Use the tools you already use",
      "Pause or cancel anytime",
        ],
    }

    const features = [

        {
            name: "Subscribe",
            desc: "Subscribe to a plan & request as many designs as you’d like.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
        },

        {
            name: "Flexible",
            desc: "Receive your design within two business days on average.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>
        },
        {
            name: "Secure",
            desc: "Design as you know it is out the door. Design as you want it just arrived.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                </svg>
        },
        {
            name: "Smooth",
            desc: "We'll revise the designs until you're 100% satisfied.",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
        },

    ]


  return (
    <section className='relative py-14 font-mono' id="plans">
    <div class="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
</div>
    <div className="max-w-screen-xl mx-auto text-gray-600 md:px-8" data-aos="fade-up">
        <div className='relative max-w-xl space-y-3 px-4 md:px-0'>
            <h3 className="text-md font-semibold text-gray-700 border-[1px] rounded-3xl border-gray-700 w-36 p-2 flex items-center justify-center mb-4 hover:-translate-y-1 hover:scale-100 duration-300">
                Pricing
            </h3>
            <p className='text-gray-700 text-3xl font-semibold sm:text-4xl'>
                Pay as you grow
            </p>
            <div className='max-w-xl'>
                <p>
                Get flexible monthly access to seasoned designers who will embed with your team, execute on your roadmap, and partner with you on your biggest product, brand, and/or marketing priorities.
                </p>
            </div>
        </div>
        <div className='mt-16 justify-between gap-8 md:flex'>
            <ul className="flex-1 max-w-md space-y-10 px-4 md:px-0 text-gray-700">
                {
                    features.map((item, idx) => (
                        <li key={idx} className="flex gap-x-3">
                            <div className="flex-none w-12 h-12 rounded-full bg-indigo-50 text-[#FAE7A0] flex items-center justify-center">
                                {item.icon}
                            </div>
                            <div>
                                <h4 className="text-lg text-gray-700 font-medium">
                                    {item.name}
                                </h4>
                                <p className="text-gray-600 mt-2 md:text-sm">
                                    {item.desc}
                                </p>
                            </div>
                        </li>
                    ))
                }
            </ul>
            <div className="flex-1 flex flex-col border-y mt-6 md:max-w-xl md:rounded-xl md:border md:border-x-none md:shadow-lg md:mt-0">
            <Tabs/>
            </div>
                {/* <div className="p-4 py-8 border-b md:p-8 bg-white">
                    <div className="justify-between flex">
                        <div className="max-w-xs">
                            <span className='text-2xl text-gray-700 font-semibold sm:text-3xl'>
                                {plan.name}
                            </span>
                            <p className="mt-3 sm:text-sm">
                                {plan.desc}
                            </p>
                        </div>
                        <div className='flex-none text-gray-700 text-2xl font-semibold sm:text-3xl'>
                            ${plan.price} <span className="text-xl text-gray-600 font-normal">/mo</span>
                        </div>
                    </div>
                    <a href='/Contact'>
                    <button className='mt-4 px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 bg-[#FAE7A0] hover:bg-transparent hover:border-2 hover:border-black'>
                        Get Started
                    </button>
                    </a>
                </div>
                <ul className='p-4 space-y-3 sm:grid sm:grid-cols-2 md:block md:p-8 lg:grid bg-white'>
                    <div className="pb-2 col-span-2 text-gray-800 font-medium">
                        <p>Features</p>
                    </div>
                    {
                        plan.features.map((featureItem, idx) => (
                            <li key={idx} className='flex items-center gap-5'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-5 w-5 text-[#FAE7A0]'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'>
                                    <path
                                        fill-rule='evenodd'
                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                        clip-rule='evenodd'></path>
                                </svg>
                                {featureItem}
                            </li>
                        ))
                    }
                </ul>
            </div> */}





        </div>
    </div>
</section>
  )
}

export default PricePlans
