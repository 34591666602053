import { useState } from 'react'
import Modal from './Modal'

function EmailPopUp() {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='h-[240px] py-10 flex flex-col items-center gap-6 text-gray-700 font-mono bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'>
      <h1 className='text-4xl font-thin mt-4 text-center'>Need a Social Media Design Starter Kit?</h1>
        <button onClick={() => setShowModal(true)} className='bg-[#FAE7A0] px-4 py-3 rounded-lg text-lg text-gray-700 font-mono'>Free Starter Kit</button>
        {showModal && <Modal onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default EmailPopUp
