import React from 'react'
import PowerAppsLogo from '../../assets/powerapps-logo.svg'
import PowerAutotoLogo from '../../assets/power-automate-logo.svg'
import PowerPagesLogo from '../../assets/power-pages-logo.svg'

function ServicesLanding() {
    const features = [
        {
            icon:
           <img src={PowerAppsLogo} alt="/" />,
            title: "POWERAPPS CONSULTING SERVICES",
            desc: "Reduce your development time and costs using pre-built services, connectors and data sources. Connect to your data, add business logic and build apps that work in your web browser or on your mobile device."
        },
        {
            icon:
            <img src={PowerPagesLogo} alt="/" />,
            title: "POWER PAGES CONSULTING",
            desc: "Improve efficiency, increase communication and streamline your business process with secure sites. Build sites for all of your business processes including onboarding, tracking, vendor management and more."
        },
        {
            icon:
            <img src={PowerAutotoLogo} alt="/" />,
            title: "POWER AUTOMATE CONSULTING SERVICES",
            desc: "Increase your productivity and cut costs with powerful apps designed to automate processes. Benefit from hundreds of pre-built connectors available for popular apps and other Microsoft cloud services."
        },



    ]
  return (
    <section className="py-24 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" id='servicespowerapps'>
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="max-w-xl space-y-3">
            <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Improve Your Business with the Power Platform
            </p>
            <p>
            Build cost-effective end-to-end business solutions faster with Microsoft's no-code/low-code suite of tools.
            </p>
        </div>
        <div className="mt-12">
            <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                {
                    features.map((item, idx) => (
                        <li key={idx} className="space-y-3">
                        <div className="text-blue-900 pb-3 w-14">
                                        {item.icon}
                                    </div>

                            <h4 className="text-lg text-gray-800 font-semibold">
                                {item.title}
                            </h4>
                            <p>
                                {item.desc}
                            </p>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
</section>
  )
}

export default ServicesLanding
