import React from "react";
import NavLanding from "../components/NewHomeLandpage/NavLanding.js";
import HeroLanding from "../components/NewHomeLandpage/HeroLanding.js";
import PowerLanding from "../components/NewHomeLandpage/PowerLanding.js";
import BenefitsLanding from "../components/NewHomeLandpage/BenefitsLanding.js";
import ReviewLanding from "../components/NewHomeLandpage/ReviewLanding.js";
import ConsultingLanding from "../components/NewHomeLandpage/ConsultingLanding.js";
import SupportLanding from "../components/NewHomeLandpage/SupportLanding.js";
import CaseStudyLanding from "../components/NewHomeLandpage/CaseStudyLanding.js";
import ServicesLanding from "../components/NewHomeLandpage/ServicesLanding.js";
import InfoLanding from "../components/NewHomeLandpage/InfoLanding.js";
import FooterLanding from '../components/NewHomeLandpage/FooterLanding.js'
import ConectLanding from "../components/NewHomeLandpage/ConectLanding.js";
import ProsLanding from '../components/NewHomeLandpage/ProsLanding.js'


function PowerApps() {
  return (
    <div class="overflow-visible">
     <NavLanding/>
    <HeroLanding/>
    <ConsultingLanding/>
    <BenefitsLanding/>
    <ReviewLanding/>
    <PowerLanding/>
    <SupportLanding/>
    <CaseStudyLanding/>
    <ServicesLanding/>
    <InfoLanding/>
    <ProsLanding/>
    <ConectLanding/>
    <FooterLanding/>


    </div>
  );
}

export default PowerApps;
