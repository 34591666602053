import React from 'react'

function Capabilities() {
  return (
    <section>
    <div>Capabilities</div>

</section>
  )
}

export default Capabilities
