import {React, useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import TeamPhoto from '../assets/TeamPhoto.mp4'

function TeamImage() {
    useEffect(() => {
        AOS.init({duration:1200})
      })
    const stats = [
        {
            data: "1K+",
            title: "Customers"
        },
        {
            data: "10+",
            title: "Countries"
        },

        {
            data: "10K+",
            title: "Content Created"
        },

    ]
  return (
    <section className="py-14 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" data-aos="fade-up">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
        <div className="sm:hidden lg:block lg:max-w-xl">
        <video
              src={TeamPhoto}
              className="mx-auto rounded-3xl object-cover md:h-[400px] md:w-[800px] h-[200px] w-[100%] md:mt-0 mt-4"
              alt="MainPhoto"
              autoPlay
          loop
          muted
            >
            </video>
        </div>
        <div className="mt-16 gap-12 sm:mt-0 md:flex lg:block">
        {/* <div className="mt-10">
          <h3 className="text-md font-semibold font-mono text-gray-900 border-[1px] rounded-3xl border-gray-800 w-36 p-2 mb-4 text-center hover:-translate-y-1 hover:scale-100 duration-300">
            Team
          </h3>
        </div> */}
            <div className="max-w-2xl font-mono mt-4">
                <h3 className="text-gray-700 text-3xl font-semibold sm:text-5xl">
                About Us
                </h3>
                <p className="mt-3 max-w-xl">
                We’re a creative digital agency offering comprehensive solutions to your most pressing problems. With over 7+ years in UX/UI design, we know how to elevate your user’s experience and translate that into metrics you can actually see: <span className='underline text-yellow-500'>more clients and bigger sales</span>.
                </p>
            </div>
          <div className="flex-none mt-6 md:mt-0 lg:mt-6">
                <ul className="inline-grid gap-y-8 gap-x-14 grid-cols-3 font-mono">
                    {
                        stats.map((item, idx) => (
                            <li key={idx} className="">
                                <h4 className="text-4xl text-yellow-500 font-semibold">{item.data}</h4>
                                <p className="mt-3 font-medium">{item.title}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default TeamImage
