import { useEffect } from "react";
import Logo1 from "../assets/logo1.png";
import Logo2 from "../assets/logo2.png";
import Logo3 from "../assets/logo3.png";
import Logo4 from "../assets/logo4.png";
import Logo5 from "../assets/logo5.png";
import Logo6 from "../assets/logo6.png";
import "../index.css";
import AOS from "aos";
import "aos/dist/aos.css";

function StatsBanner() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <section>

        <div class="absolute inset-0 -z-10 h-full w-full"></div>
        <div className="py-2 bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]">
          <div className=" mx-auto md:px-14 px-4 flex-wrap gap-x-12 py-4 " data-aos="fade-up">
          <div className="relative mx-auto sm:text-left md:mt-28 ml-2 font-mono">
          {/* <h3 className="text-md md:ml-28 font-semibold text-gray-700 border-[1px] rounded-3xl border-gray-700 w-56 p-2 flex items-center justify-center mb-4 hover:-translate-y-1 hover:scale-100 duration-300">
            Brands we help grow
          </h3> */}
        </div>
            <div className="flex space-x-40 group overflow-hidden">

              <div className="flex space-x-40 animate-loop-scroll group-hover:paused">
                <img
                  loading="lazy"
                  src={Logo1}
                  alt="Image 1"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo2}
                  alt="Image 2"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo3}
                  alt="Image 3"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo4}
                  alt="Image 4"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo5}
                  alt="Image 5"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo6}
                  alt="Image 6"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo1}
                  alt="Image 1"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo2}
                  alt="Image 2"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo3}
                  alt="Image 3"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo4}
                  alt="Image 4"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo5}
                  alt="Image 5"
                  className="h-20 w-40"
                />
                <img
                  loading="lazy"
                  src={Logo6}
                  alt="Image 6"
                  className="h-20 w-40"
                />
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default StatsBanner;
